<template>
    <div>
        <p>Template {{ email_template_label }}</p>
        <p>Audience {{ email_audience_label }}</p>
        <p class="text-caption">{{ id }}</p>
    </div>
</template>
<style scoped>
p {
    margin-bottom: 0px;
}
</style>
<script>
export default {

    props: {
        attr: {
            type: Object,
        },
    },

    computed: {
        id() {
            return this.attr.id;
        },
        email_template_label() {
            return this.attr.email_template_label;
        },
        email_audience_label() {
            return this.attr.email_audience_label;
        },
    },

};
</script>
