<template>
    <v-card tile elevation="4" class="pa-0">
        <v-toolbar short flat color="white">
            <v-toolbar-title class="green--text">{{label}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="teal darken-2" @click="openCreateEmailDispatchDialog">
                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width/>
            </v-btn>
            <v-menu offset-y left>
                <template v-slot:activator="{ on }">
                    <v-btn icon color="teal darken-2" v-on="on">
                        <font-awesome-icon :icon="['far', 'ellipsis-v']" style="font-size: 20px;" fixed-width/>
                    </v-btn>
                    <!-- <v-tab v-on="on" class="green--text">
                        <Avatar :attr="mainProfile" :size="36"/>
                        <font-awesome-icon icon="caret-down" class="pl-2 green--text" fixed-width size="1x"/>
                    </v-tab> -->
                </template>
                <v-list class="ma-0 pa-0">
                    <v-list-item-group>
                    <v-list-item :to="{ name: 'service-admin-site-settings' }">
                        <!-- <v-list-item-content> -->
                            <v-list-item-title>Settings</v-list-item-title>
                        <!-- </v-list-item-content> -->
                    </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-menu>
        </v-toolbar>
        <!-- <v-divider></v-divider> -->
        <v-list dense nav>
            <v-list-item v-if="list.length === 0">No dispatches yet.</v-list-item>
            <v-list-item v-for="item in list" v-bind:key="item.id" class="my-2" @click="onClickItem(item.id)">
                <v-list-item-content class="text-start">
                    <!-- <EmailDispatchListItem :attr="item" :link="!selectOne"></EmailDispatchListItem> -->
                    <EmailDispatchListItem :attr="item"></EmailDispatchListItem>
                </v-list-item-content>
                <v-list-item-action>
                <v-menu offset-y left>
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="teal darken-2" v-on="on">
                            <font-awesome-icon :icon="['far', 'ellipsis-v']" style="font-size: 20px;" fixed-width/>
                        </v-btn>
                        <!-- <v-tab v-on="on" class="green--text">
                            <Avatar :attr="mainProfile" :size="36"/>
                            <font-awesome-icon icon="caret-down" class="pl-2 green--text" fixed-width size="1x"/>
                        </v-tab> -->
                    </template>
                    <v-list class="ma-0 pa-0">
                        <v-list-item-group>
                        <v-list-item :to="{ name: 'account-delete-email-dispatch', params: { accountId, emailDispatchId: item.id } }">
                            <!-- <v-list-item-icon>
                                <font-awesome-icon :icon="['fas', 'trash']" style="font-size: 20px; color: red" fixed-width/>
                            </v-list-item-icon> -->
                            <v-list-item-content>
                                <v-list-item-title style="color: red;">Delete...</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-menu>
                </v-list-item-action>
            </v-list-item>
        </v-list>
        <!-- <v-row justify="center" class="py-5" v-show="displayCreateEmailDispatch">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
            <v-card elevation="4" class="px-10 pb-5 pt-8">
                <v-template v-model="createEmailDispatch" ref="createEmailDispatchRef" @submit="validateCreateDispatch" onSubmit="return false;" @keyup.enter.native="validateCreateDispatch">
                    <div v-if="add">
                        <v-select :items="addEmailDispatchChoices" v-model="addEmailDispatchId" label="Select an template"></v-select>
                    </div>
                    <div v-if="create">
                        <v-text-field
                            v-model=name
                            label="Name"
                            :rules="nameRules"
                            validate-on-blur
                            color="teal darken-2"
                            required
                            hint="What should we call you?"
                            autofocus
                            outlined
                        >
                                            <template v-slot:prepend>
                        <font-awesome-icon icon="site" fixed-width class="mt-1"/>
                    </template>
                        </v-text-field>
                        <v-text-field
                            ref="nameInputRef"
                            v-model=newDispatchName
                            label="Display Name"
                            :rules="newDispatchNameRules"
                            validate-on-blur
                            color="teal darken-2"
                            required
                            hint="The name for the template"
                            type="text"
                            outlined
                        >
                        </v-text-field>

                    </div>
                    <v-row justify="center">
                        <v-card-actions>
                            <v-btn elevation="4" class="green white--text" @click="addDispatch" :disabled="!createEmailDispatch" v-if="add">
                                <font-awesome-icon icon="check" fixed-width/>
                                <span class="ml-2">Add</span>
                            </v-btn>
                            <v-btn elevation="4" class="green white--text" @click="createDispatch" :disabled="!createEmailDispatch" v-if="create">
                                <font-awesome-icon icon="check" fixed-width/>
                                <span class="ml-2">Create</span>
                            </v-btn>
                        </v-card-actions>
                    </v-row>
                </v-template>
            </v-card>
            </v-col>
        </v-row> -->
        <!-- <CreateDispatchDialog v-model="createDispatchDialogVisible" @cancel="createDispatchDialogVisible = false" @created="createDispatchDialogVisible = false"/> -->
    </v-card>
</template>

<script>
import EmailDispatchListItem from '@/components/list-item/EmailDispatchListItem.vue';
// import CreateDispatchDialog from '@/components/account-dashboard/CreateDispatchDialog.vue';
import { isValidName, compact } from '@/sdk/input';

export default {
    components: {
        EmailDispatchListItem,
        // CreateDispatchDialog,
    },

    props: {
        domain: {
            type: String,
            default: null,
            required: false,
        },
    },

    data: () => ({
        // TODO: eliminate these temporary flags and usage in the dispatch; maybe create a different component for "select a dispatch"
        selectOne: false,
        add: false,
        create: true,

        list: [],
        // createDispatchDialogVisible: false,
        // create dispatch
        displayCreateEmailDispatch: false,
        createEmailDispatch: null,
        newEmailDispatchName: null,
        newEmailDispatchNameRules: [
            (v) => !!v || 'Display name is required',
            (v) => !v || isValidName(compact(v)) || 'Email dispatch name is required',
        ],
        // add dispatch
        addEmailDispatchChoices: [],
        addEmailDispatchId: null,
    }),

    computed: {
        accountId() {
            return this.$route.params.accountId;
        },
        // id() {
        //     return this.policyId;
        // },
        label() {
            if (this.selectOne) {
                return 'Select an email dispatch';
            }
            switch (this.list.length) {
            case 0:
                return 'No email dispatches';
            case 1:
                return '1 email dispatch';
            default:
                return `${this.list.length} email dispatches`;
            }
        },
    },

    watch: {
        displayCreateEmailDispatch(value) {
            if (value && (this.add || this.create)) {
                this.initAddEmailDispatchChoices();
            }
        },
    },

    methods: {
        async loadEmailDispatchList() {
            try {
                this.$store.commit('loading', { loadEmailDispatchList: true });
                const match = {};
                if (typeof this.domain === 'string') {
                    match.domain = this.domain;
                }
                const response = await this.$client.account(this.$route.params.accountId).emailDispatch.search(match);
                if (response?.list) {
                    this.list = response.list;
                }
            } catch (err) {
                console.error('loadEmailDispatchList failed', err);
            } finally {
                this.$store.commit('loading', { loadEmailDispatchList: false });
            }
        },
        onClickItem(id) {
            /*
            if (this.selectOne) {
                this.$emit('selected', { emailDispatchId });
            }
            */
            this.$emit('selected', { id });
        },
        openCreateEmailDispatchDialog() {
            // this.createDispatchDialogVisible = true;
            this.$router.push({ name: 'account-create-email-dispatch', params: { accountId: this.$route.params.accountId }, query: { domain: this.$route.params.domain } });
        },
        // async createDispatch() {
        //     this.error = false;
        //     console.log('createDispatch');
        //     const request = {
        //         // name: this.name,
        //         name: this.newDispatchName,
        //         // agreeToTerms: this.isAgreeToTermsChecked,
        //         // interactionId: this.interactionId, // will be present if dispatch arrives from an existing interaction, such as clicking on a specific link to get started and we can use this to redirect the dispatch to an appropriate location after dispatch is created
        //     };
        //     const response = await this.$client.account(this.$route.params.accountId).site.create(request);
        //     console.log('createDispatch response: %o', response);
        //     const { isCreated, id, error } = response;
        //     if (isCreated) {
        //         const dispatch = { id, ...request };
        //         this.list.push(site);
        //         this.$emit('created-site', dispatch);
        //         this.$emit('added-site', dispatch);
        //         this.displayCreateEmailDispatch = false;
        //         this.newDispatchName = null;
        //     } else if (error) {
        //         this.error = error;
        //     } else {
        //         this.error = 'Request failed';
        //     }
        // },
        // validateCreateDispatch() {
        //     if (this.$refs.createEmailDispatchRef.validate()) {
        //         this.createDispatch();
        //     }
        // },

        // async addDispatch() {
        //     const dispatch = { id: this.addEmailDispatchId, label: this.addEmailDispatchChoices.find((item) => item.value === this.addEmailDispatchId).text };
        //     this.list.push(site);
        //     this.$emit('added-site', dispatch);
        //     this.displayCreateEmailDispatch = false;
        //     this.addEmailDispatchId = null;
        // },
        // async initAddDispatchChoices() {
        //     // create a map of which dispatch ids are already in the list
        //     const dispatchMap = {};
        //     this.list.forEach((item) => {
        //         dispatchMap[item.id] = true;
        //     });
        //     // when we show the add/create dispatch dialog, load the list of available dispatches to add to the policy (list of all dispatches less the ones already in the policy)
        //     const result = await this.$client.account(this.$route.params.accountId).site.list();
        //     console.log(`DispatchViewList.vue fetch dispatches result: ${JSON.stringify(result)}`);
        //     if (result && result.list) {
        //         // filter the results to remove dispatches already in the policy, then convert the available dispatches to a choice list for the v-select [ { text, value }, ... ]
        //         this.addEmailDispatchChoices = result.list.filter((item) => !siteMap[item.id]).map((item) => ({ text: item.name, value: item.id }));
        //     }
        // },
    },

    mounted() {
        this.loadEmailDispatchList();
    },
};
</script>
